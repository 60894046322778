/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { zilaSlab } from 'assets/theme/theme';
import { BackButton, DefaultButton, ProductImageView } from 'common/presentation/components';
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	SvgIcon,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Dropdown } from 'primereact/dropdown';
import { createBreadCrumb, getStockColors } from 'common/utils/general.utils';
import { ImageDto, StockStatus } from 'features/products/domain/entities/Product';
import { Tag } from 'primereact/tag';
import { Editor } from 'primereact/editor';
import { Galleria, GalleriaResponsiveOptions } from 'primereact/galleria';
import {
	FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadUploadEvent,
} from 'primereact/fileupload';
import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage-utils';
import { useNavigate, useParams } from 'react-router-dom';
import useProductBackoffice from '../../provider/product-backoffice.provider';
import { ProductModel } from '@/features/products/data/dto/product-model';
import { ProgressBar } from 'primereact/progressbar';
import { ApiResponse } from '@/common/domain/entities/api-response';
import { TreeNode } from 'primereact/treenode';
import {
	Tree, TreeCheckboxSelectionKeys, TreeExpandedKeysType, TreeMultipleSelectionKeys,
} from 'primereact/tree';
import useSearch from 'features/products/presentation/provider/search.provider';
import { ProductCategoryModel } from 'features/products/data/dto/product-category-model';
import { useMountEffect } from 'primereact/hooks';
import { Tax } from '@/features/products/domain/entities/Tax';
import { useTranslation } from 'react-i18next';
import { AppEnv } from 'common/constants/app-env';
import Tags from '../../components/tags/Tags';
import { currency } from 'common/utils/currency-utils';
import DeleteIcon from '@mui/icons-material/Delete';

export const ProductForm = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const [statuses] = useState<StockStatus[]>([
		{ stockStatusId: 1, name: 'Sem Stock', description: '' },
		{ stockStatusId: 2, name: 'Stock Baixo', description: '' },
		{ stockStatusId: 3, name: 'Stock Médio', description: '' },
		{ stockStatusId: 4, name: 'Stock Alto', description: '' },
	]);
	const { id } = useParams();
	const {
		getProductById,
		product,
		productState,
		updateProduct,
		productsUpdateState,
	} = useProductBackoffice();
	const { categories, getCategories } = useSearch();

	const [edit, setEdit] = useState<ProductModel>();
	const [images, setImages] = useState<string[]>([]);
	const [newImages, setNewImages] = useState<string[]>([]);
	const [activeIndex, setActiveIndex] = useState(0);
	const toast = useRef<Toast>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [nodes, setNodes] = useState<TreeNode[]>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [selectedKeys, setSelectedKeys] = useState<
		string | TreeCheckboxSelectionKeys | TreeMultipleSelectionKeys | null>(null);
	const [expandedKeys, setExpandedKeys] = useState<TreeExpandedKeysType>({ 65: true, '0-0': true });

	const fileUploadRef = useRef<FileUpload>(null);
	const [totalSize, setTotalSize] = useState(0);

	const responsiveOptions: GalleriaResponsiveOptions[] = [
		{
			breakpoint: '991px',
			numVisible: 4,
		},
		{
			breakpoint: '767px',
			numVisible: 3,
		},
		{
			breakpoint: '575px',
			numVisible: 1,
		},
	];

	const categoriesToNode = (cat: ProductCategoryModel[]) => {
		if (cat == null || cat.length === 0) {
			return [] as TreeNode[];
		}
		const list: TreeNode[] = cat.map((item) => ({
			key: item.productCategoryId,
			label: item.name,
			data: item.description,
			children: categoriesToNode(item.childrenCategories),

		} as TreeNode));
		return list;
	};

	useEffect(() => {
		getCategories();
		if (id) {
			const identification = parseInt(id || '1', 10);
			getProductById(identification);
		}
	}, []);

	useEffect(() => {
		if (product) {
			setImages(product.images);
		}

		if (product) {
			setEdit(product);
		}
	}, [product]);

	useEffect(() => {
		const nod = categoriesToNode(categories);
		setNodes(nod);
	}, [categories]);

	useEffect(() => {
		if (product) {
			setSelectedKeys({
				[product.category?.productCategoryId?.toString() ?? '']: {
					checked: true,
					partialChecked: false,
				},
			});
			const expanded: TreeExpandedKeysType = {};
			createBreadCrumb(categories, product.category?.productCategoryId).forEach((item) => {
				expanded[item.productCategoryId] = true;
			});
			setExpandedKeys(expanded);
		}
	}, [nodes]);

	const onTemplateClear = () => {
		setTotalSize(0);
	};

	const handleSubmit = () => {
		if (edit) {
			const nImage: ImageDto[] = newImages
				.filter((item) => !!item)
				.map((item) => ({
					id: 0,
					url: item.split(/(\\|\/)/).pop()?.split(/(\\|\/|")/)[0],
				} as ImageDto));

			updateProduct(edit.productId, {
				name: edit.name,
				reference: edit.reference,
				barcode: edit.barcode,
				price: parseFloat(edit.priceWithoutTaxes?.toString()?.replaceAll(',', '.') ?? ''),
				brandId: 1,
				productCategoryId: parseInt(Object.keys(selectedKeys || {}).slice(-1)[0], 10),
				taxCategoryId: edit.tax?.taxCategoryId,
				stockStatusId: edit.stockStatus.stockStatusId,
				description: edit.description,
				imageUrls: edit.imageUrls,
				imageFiles: [...(edit.imageFiles || []), ...nImage],
				videoUrl: edit.videoUrl,
			});
		}
	};

	const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setEdit((prev) => ({
			...prev, priceWithoutTaxes: e.target.value,
		} as unknown as ProductModel));
	};

	const chooseOptions = {
		icon: 'pi pi-fw pi-images',
		iconOnly: true,
		className: 'custom-choose-btn p-button-rounded p-button-outlined',
		style: {
			width: 200,
			height: 140,
			alignText: 'center',
		},
	};

	return (
		<Box>
			<Toast ref={toast} />
			{(productsUpdateState.loading) && (
				<ProgressBar mode="indeterminate" style={{ height: '6px' }} />
			)}
			<Box>
				<BackButton onClick={() => navigate(-1)} />
			</Box>
			<Stack direction="row" mt={4}>
				<Typography fontSize={22} fontFamily={zilaSlab} fontWeight={600}>
					Detalhes de produto
				</Typography>
				<Box sx={{ flexGrow: 1 }} />
				<DefaultButton
					disabled={productsUpdateState.loading}
					sx={{ borderRadius: 3, fontSize: 14 }}
					onClick={handleSubmit}
				>
					<SvgIcon component={AddIcon} fontSize="small" sx={{ mr: 1 }} />
					{t('save_changes')}
				</DefaultButton>
			</Stack>

			{(productState.loading) && (
				<ProgressBar mode="indeterminate" style={{ height: '6px' }} />
			)}

			<Grid container mt={4} spacing={2}>
				<Grid item md={4}>
					<Card
						sx={{
							boxShadow: 'none',
							p: 4,
							gap: 2,
							display: 'flex',
						}}
					>
						<Stack direction="column" spacing={4} flex={1}>
							<div>
								<InputLabel shrink htmlFor="bootstrap-input">
									Nome de produto
								</InputLabel>
								<TextField
									fullWidth
									value={edit?.name ?? ''}
									onChange={(e) => setEdit((prev) => ({
										...prev, name: e.target.value,
									} as ProductModel))}
								/>
							</div>
							<div>
								<InputLabel shrink htmlFor="bootstrap-input">
									Referencia de produto
								</InputLabel>
								<TextField
									fullWidth
									value={edit?.reference ?? ''}
									onChange={(e) => setEdit((prev) => ({
										...prev, reference: e.target.value,
									} as ProductModel))}
								/>
							</div>

							<div>
								<InputLabel shrink htmlFor="bootstrap-input">
									Código de barras de produto
								</InputLabel>
								<TextField
									fullWidth
									value={edit?.barcode ?? ''}
									onChange={(e) => setEdit((prev) => ({
										...prev, barcode: e.target.value,
									} as ProductModel))}
								/>
							</div>
							<Grid container gap={2}>
								<Grid item md={6}>

									<InputLabel shrink htmlFor="bootstrap-input">
										Preço de produto
									</InputLabel>
									<TextField
										fullWidth
										value={edit?.priceWithoutTaxes ?? 0}
										onChange={(e) => handlePriceChange(currency(e))}
									/>

								</Grid>
								<Grid item md={5}>
									<div>
										<InputLabel shrink>
											Taxa de IVA
										</InputLabel>
										<Select
											id="tax-select"
											label="vat"
											value={edit?.tax?.taxCategoryId ?? 1}
											fullWidth
											onChange={(e) => setEdit((prev) => ({
												...prev,
												tax: {
													taxCategoryId: e.target.value,
												},
											} as ProductModel))}
										>
											<MenuItem value={1}>23%</MenuItem>
										</Select>
									</div>
								</Grid>
							</Grid>
							<Stack>
								<Dropdown
									options={statuses}
									placeholder="Select a Status"
									optionLabel="name"
									optionValue="stockStatusId"
									value={edit?.stockStatus?.stockStatusId ?? 1}
									onChange={(e) => setEdit((prev) => ({
										...prev, stockStatus: { stockStatusId: e.value },
									} as ProductModel
									))}
									// eslint-disable-next-line react/no-unstable-nested-components
									itemTemplate={(ev) => {
										const { color, bgColor } = getStockColors(ev.stockStatusId);
										return (
											<Stack>
												<Tag
													value={ev.name}
													style={{ backgroundColor: bgColor, color }}
												/>
											</Stack>
										);
									}}
								/>
							</Stack>
							<Stack>
								<InputLabel shrink htmlFor="bootstrap-input">
									Descrição de produto
								</InputLabel>
								<Editor
									style={{ height: '320px' }}
									value={edit?.description || ''}
									onTextChange={(e) => setEdit((prev) => ({
										...prev, description: e.htmlValue,
									} as ProductModel))}
								/>
							</Stack>
						</Stack>
					</Card>
				</Grid>

				<Grid item md={8}>
					<Card
						elevation={0}
						sx={{
							boxShadow: 'none',
							p: 4,
							gap: 2,
							display: 'flex',
						}}
					>
						<Stack direction="column" spacing={4} flex={1}>

							<div
								style={{ position: 'relative' }}
							>
								<video
									key="product-images-video"
									controls
									src={edit?.videoUrl}
									width="90%"
									style={{ objectFit: 'contain', background: '#CCCCCC7d' }}
								>
									<source src={edit?.videoUrl} />
									<track kind="captions" />
								</video>
							</div>

							<div>
								<InputLabel shrink htmlFor="bootstrap-input">
									{t('video_url')}
								</InputLabel>
								<TextField
									fullWidth
									value={edit?.videoUrl ?? ''}
									onChange={(e) => setEdit((prev) => ({
										...prev, videoUrl: e.target.value,
									} as ProductModel))}
								/>
							</div>

							<div>

								<InputLabel shrink htmlFor="bootstrap-input">
									{t('images_by_url')}
								</InputLabel>
								<div style={{
									display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 12,
								}}
								>
									{edit?.imageUrls?.map((el, index) => (
										<Card
											sx={{
												width: 200,
												boxShadow: ` 0px 0px 1.80666px ${theme.palette.secondary.main}`,
												borderTopLeftRadius: 0,
												borderTopRightRadius: 0,
											}}
											key={`product-images-url-${index.toString()}`}
										>
											<CardMedia
												component="img"
												height="140"
												image={el.url}
												alt={edit.name}
												sx={{ objectFit: 'contain', height: '150px' }}
											/>
											<CardContent>
												<div>
													<InputLabel shrink htmlFor="bootstrap-input">
														URL
													</InputLabel>
													<TextField
														fullWidth
														size="small"
														value={el.url ?? ''}
														onChange={(e) => {
															if (edit?.imageUrls) {
																edit.imageUrls[index].url = e.target.value;
															}
															return setEdit((prev) => ({
																...prev, ...edit,
															} as ProductModel));
														}}
													/>
												</div>
											</CardContent>
										</Card>
									))}
								</div>

							</div>

							<div>
								<InputLabel shrink htmlFor="bootstrap-input">
									{t('images_extras')}
								</InputLabel>

								<div style={{
									display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 12,
								}}
								>

									{edit?.imageFiles?.map((el, index) => (
										<Card
											sx={{
												width: 200,
												boxShadow: ` 0px 0px 1.80666px ${theme.palette.secondary.main}`,
												borderTopLeftRadius: 0,
												borderTopRightRadius: 0,
											}}
											key={`product-images-extra-${index.toString()}`}
										>
											<Stack sx={{ position: 'relative' }}>

												<IconButton
													sx={{ position: 'absolute', right: 0, top: 0 }}
													onClick={() => {
														const idx = edit?.imageFiles?.indexOf(el);
														if (idx || idx === 0) {
															const arr = edit?.imageFiles || [];
															arr.splice(idx, 1);

															setEdit((prev) => ({
																...prev, imageFiles: arr,
															} as ProductModel));
														}
													}}
												>
													<DeleteIcon />
												</IconButton>
												<CardMedia
													component="img"
													height="140"
													image={el.url}
													alt={el.url}
													sx={{ objectFit: 'contain', height: '150px' }}
												/>
											</Stack>
										</Card>
									))}

									{newImages?.map((el, index) => (
										<Card
											sx={{
												width: 200,
												boxShadow: ` 0px 0px 1.80666px ${theme.palette.secondary.main}`,
												borderTopLeftRadius: 0,
												borderTopRightRadius: 0,
											}}
											key={`product-images-extra-${index.toString()}`}
										>

											<Stack sx={{ position: 'relative' }}>

												<IconButton
													sx={{ position: 'absolute', right: 0, top: 0 }}
													onClick={() => {
														const idx = newImages.indexOf(el);
														const arr = newImages;
														arr.splice(idx, 1);
														setNewImages([...arr]);
													}}
												>
													<DeleteIcon />
												</IconButton>
												<CardMedia
													component="img"
													height="140"
													image={el}
													alt="uploaded new"
													sx={{ objectFit: 'contain', height: '150px' }}
												/>
											</Stack>

										</Card>
									))}

									<FileUpload
										name="files"
										url={`${AppEnv.BASE_URL}backoffice/files/images`}
										multiple
										mode="basic"
										auto
										accept="image/*"
										maxFileSize={1000000}
										style={{ marginTop: 16 }}
										onBeforeSend={(e) => {
											const key = LocalStorageWrapper.get<string>(LocalStorageKeys.AUTH_TOKEN);
											if (key) {
												e.xhr.setRequestHeader('Authorization', `Bearer ${key}`);
											}
										}}
										onUpload={(e) => {
											const files = e.files.map((file: any) => file.objectURL);
											const result = JSON.parse(
												e.xhr.response,
											) as ApiResponse<{ fileUrl: string }[]>;
											setImages((prev) => [...prev, ...files]);

											setNewImages((prev) => [
												...prev, ...result.data.map((item) => item.fileUrl)]);
										}}
										onError={onTemplateClear}
										chooseOptions={chooseOptions}
									/>

								</div>
							</div>

							<div>
								<InputLabel shrink htmlFor="bootstrap-input">
									Categoria de produto
								</InputLabel>
								<Tree
									value={nodes}
									selectionMode="checkbox"
									selectionKeys={selectedKeys}
									propagateSelectionUp
									metaKeySelection
									expandedKeys={expandedKeys}
									onToggle={(e) => setExpandedKeys(e.value)}
									onNodeClick={(e) => setSelectedKeys({
										// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
										[e.node.key!.toString()]: {
											checked: true, partialChecked: false,
										},
									})}
									className="w-full"
								/>
							</div>
						</Stack>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
};
